import { create } from "zustand";

export const useAuth = create((set) => ({
  // Initial state: Load user data from local storage or default to an empty object
  user: JSON.parse(window.localStorage.getItem("user")) ?? {},
  loggedIn:
    Object.keys(JSON.parse(window.localStorage.getItem("user") || "{}"))
      .length > 0
      ? true
      : false,

  // Action to log in the user: Update both Zustand state and local storage
  setUserLogin: (data) => {
    window.localStorage.setItem("user", JSON.stringify(data));
    set({ user: data, loggedIn: true });
  },

  // Optionally, you might want to have an action to log out the user
  logout: () => {
    window.localStorage.removeItem("user");
    set({ user: [] });
    window.location.href = "/";
  },
}));
