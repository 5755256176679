import React, { useEffect, useState } from "react";
import iconNote from "../images/Note.svg";
import iconClock from "../images/clock.png";
import iconAttach from "../images/Attach.svg";
import iconCart from "../images/Cart.svg";
import iconLogout from "../images/Logout.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../store/AuthStore";
import axios from "axios";
import { BASE } from "../lib/apiBase";
import io from "socket.io-client";
// const socket = io("http://localhost:8800");
function BillUser() {
  const { user, loggedIn, logout } = useAuth();
  const [data, setData] = useState([]);
  console.log("🚀 ~ BillUser ~ data:", data);
  const [socket, setSocket] = useState(null);
  const [edit, setEdit] = useState(true);
  const navigate = useNavigate();
  const id = user?._id;

  const toDay = new Date();
  const year = toDay.getFullYear();
  const month = String(toDay.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하기 때문에 +1이 필요하고, 한 자리 수일 경우 앞에 '0'을 붙입니다.
  const date = String(toDay.getDate()).padStart(2, "0"); // 일이 한 자리 수일 경우 앞에 '0'을 붙입니다.
  const formattedDate = `${year}-${month}-${date}`;
  const BranchOrderData = async () => {
    try {
      const { data } = await axios.get(
        `${BASE}/branch/orderget?branch=${id}&today=${formattedDate}`
      );
      if (!data?.success) {
        throw new Error(data.message);
      }
      setData(() => data.orderItemsData);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    !loggedIn && navigate("/");
  }, []);

  useEffect(() => {
    setSocket(io(BASE));
    // setSocketId(() => socket.id);
  }, []);

  useEffect(() => {
    socket?.emit("newUser", user._id);
  }, [socket, user]);

  useEffect(() => {
    const requestPermission = (data) => {
      if (window.ReactNativeWebView) {
        // 모바일이라면 모바일의 카메라 권한을 물어보는 액션을 전달합니다.
        window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
      // else {
      //   // 모바일이 아니라면 모바일 아님을 alert로 띄웁니다.
      //   alert({ message: "error" });
      // }
    };

    socket?.on("getNewOrder", (data) => {
      if (data?.branchId === user._id) {
        console.log("getNewOrder : ", data);
        requestPermission(data);
        BranchOrderData();
        // setEdit((prev) => !prev);
        // 프린터
      }
    });
  }, [socket]);

  useEffect(() => {
    BranchOrderData();
  }, [edit]);

  const handleADD = async (id) => {
    const datafill = data.filter((item) => item._id !== id);
    try {
      const { data } = await axios.put(`${BASE}/order/${id}`, {
        orderStatus: "픽업완료",
      });
      console.log(data);
      socket.emit("newPickup", {
        branchId: data.branchId,
        userId: data.userId,
        pData: data,
      });
      setEdit(!edit);
    } catch (e) {
      console.log(e);
    }
    setData(datafill);
  };

  return (
    <div className='container'>
      <div className='header'>
        {" "}
        <h1>주문 목록</h1>
        <div className='idUser'>
          <div className='name'>{user.name}</div>
          <img src={iconLogout} alt='Logout' onClick={() => logout()} />
        </div>
      </div>
      <div className=' container_Bill'>
        <div className='scroll_container'>
          {data?.map((item) => (
            <div className='Bill_Detail' key={item._id}>
              <div className='time'>
                <div className='idBill'>
                  <img src={iconNote} alt='ID_BILL' />
                  <p>{item.phone.slice(-4)}</p>
                </div>
                <div className='detailTime'>
                  <div className='hours'>
                    <img src={iconClock} alt='Clock' />
                    <p>{new Date(item.createdAt).toLocaleTimeString()}</p>
                  </div>
                  <div className='date'>
                    Date : {new Date(item.createdAt).toLocaleDateString()}
                  </div>
                </div>
              </div>
              <div className='Detail_Drink'>
                <div className='Container_Detail'>
                  {item.orderItems.map((v) => {
                    const totalOptionPrice = JSON.parse(v.option).reduce(
                      (total, op) => {
                        return op.optionPrice
                          ? total + Number(op.optionPrice)
                          : total;
                      },
                      0
                    );
                    return (
                      <div key={v._id} className='ContainerDrink'>
                        <div className='NameDrink'>
                          <p>{v.menuName}</p>
                          <div>
                            <p>{Number(v.price).toLocaleString()}원</p>
                          </div>
                        </div>
                        {JSON.parse(v.option).map((op, index) => (
                          <div className='OptionDrink NameDrink' key={index}>
                            <p>
                              {op.optionName && (
                                <>
                                  {" "}
                                  <img src={iconAttach} alt='' />
                                  <span>{op.optionGroupName}</span>{" "}
                                </>
                              )}

                              {op.optionName && (
                                <span style={{ marginLeft: "5px" }}>
                                  {" "}
                                  {op.optionName}
                                </span>
                              )}
                            </p>
                            <div>
                              {op.optionName && (
                                <p>
                                  {Number(op.optionPrice).toLocaleString()}원
                                </p>
                              )}
                            </div>
                          </div>
                        ))}
                        {totalOptionPrice !== 0 && (
                          <p
                            style={{
                              textAlign: "end",
                              paddingTop: "10px",
                              fontSize: "14px",
                              borderTop: "1px dashed rgba(0, 0, 0, 0.053)",
                              marginTop: "15px",
                            }}
                          >
                            {(
                              Number(v.price) + totalOptionPrice
                            ).toLocaleString()}
                            원
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className='Total'>
                  <p>Total</p>
                  <p>{Number(item.totalAmount).toLocaleString()}원</p>
                </div>
              </div>
              <div className='AddCart' onClick={() => handleADD(item._id)}>
                <img src={iconCart} alt='Cart' />
                <div>준비완료</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='footerReception'>
        <p>접수대기</p>
        <span>{data.length}</span>
      </div>
    </div>
  );
}

export default BillUser;
