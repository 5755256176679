import React from 'react'
import IMGLoading from '../images/GAS.png'
function Loading() {
  return (
    <div className='Loading'>
        <img src={IMGLoading} alt="GAS background"/>
    </div>
  )
}

export default Loading